import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import ProviderLayout from '../layouts/provider';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import ProviderGuestGuard from '../guards/ProviderGuestGuard';
import ProviderAuthGuard from '../guards/ProviderAuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { Roles } from '../constants/roles';
import { Modules } from '../constants/modules';
import { GuestProvider } from 'contexts/GuestProviderContext';
import ModuleBasedAuthGuard from 'guards/ModuleBasedAuthGuard';
import ModuleBasedGuestGuard from 'guards/ModuleBasedGuestGuard';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
        // { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> }
      ]
    },
    {
      path: 'admin',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <AdminLogin />
            </GuestGuard>
          )
        }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'agency',
          children: [
            { element: <Navigate to="/dashboard/agency/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.ADMIN]}>
                  <AgencyList />
                </RoleBasedGuard>
              )
            },
            {
              path: ':agencyId/provider',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.ADMIN]}>
                  <ProviderList />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'provider',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
              <ProviderList />
            </RoleBasedGuard>
          )
        },
        {
          path: 'provider/:providerId/rates',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
              <ProviderRateList />
            </RoleBasedGuard>
          )
        },
        {
          path: 'provider/:providerId/zip-codes',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
              <ModuleBasedAuthGuard moduleName={Modules.ZIPCODE_MODULE}>
                <ProviderZipCodeList />
              </ModuleBasedAuthGuard>
            </RoleBasedGuard>
          )
        },
        {
          path: 'position',
          children: [
            { element: <Navigate to="/dashboard/position/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.BILLING_MODULE}>
                    <PositionList />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'service-status',
          children: [
            { element: <Navigate to="/dashboard/service-status/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.BILLING_MODULE}>
                    <ServiceStatusList />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'calendar',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
              <ModuleBasedAuthGuard moduleName={Modules.BILLING_MODULE}>
                <HolidayList />
              </ModuleBasedAuthGuard>
            </RoleBasedGuard>
          )
        },
        {
          path: 'news',
          children: [
            { element: <Navigate to="/dashboard/news/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.NEWS_MODULE}>
                    <NewsList />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.NEWS_MODULE}>
                    <NewsForm />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: ':editNewsId/edit',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.NEWS_MODULE}>
                    <NewsForm />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'bonus',
          element: (
            <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
              <ModuleBasedAuthGuard moduleName={Modules.BONUS_MODULE}>
                <BonusForm />
              </ModuleBasedAuthGuard>
            </RoleBasedGuard>
          )
        },
        {
          path: 'billing',
          children: [
            { element: <Navigate to="/dashboard/billing/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={[Roles.AGENCY]}>
                  <ModuleBasedAuthGuard moduleName={Modules.BILLING_MODULE}>
                    <BillingList />
                  </ModuleBasedAuthGuard>
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // Guest Routes
    {
      path: 'provider',
      children: [
        {
          path: 'home',
          element: (
            <GuestProvider>
              <ProviderAuthGuard>
                <ModuleBasedGuestGuard moduleName={Modules.CLEARANCE_MODULE}>
                  <ProviderHome />
                </ModuleBasedGuestGuard>
              </ProviderAuthGuard>
            </GuestProvider>
          )
        },
        {
          path: 'h',
          element: (
            <GuestProvider>
              <ProviderAuthGuard>
                <ProviderLayout />
              </ProviderAuthGuard>
            </GuestProvider>
          ),
          children: [
            {
              path: 'clearances',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.CLEARANCE_MODULE}>
                  <ClearanceList />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'news',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.NEWS_MODULE}>
                  <NewsGallery />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'news/:newsId',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.NEWS_MODULE}>
                  <NewsView />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'billing',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.BILLING_MODULE}>
                  <GuestBillingList />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'my-schedule',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.BILLING_MODULE}>
                  <MySchedule />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'my-zip-codes',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.ZIPCODE_MODULE}>
                  <ZipCodeList />
                </ModuleBasedGuestGuard>
              )
            },
            {
              path: 'email',
              element: (
                <ModuleBasedGuestGuard moduleName={Modules.ZIPCODE_MODULE}>
                  <Email />
                </ModuleBasedGuestGuard>
              )
            }
          ]
        }
      ]
    },
    {
      path: 'find-provider/:qrCode',
      element: (
        <GuestProvider>
          <ProviderGuestGuard>
            <FindProviderByCode />
          </ProviderGuestGuard>
        </GuestProvider>
      )
    },
    {
      path: 'news/:newsSlug',
      element: <NewsView />
    },
    {
      path: 'rs/:token',
      element: <SessionView />
    },
    {
      path: 'schedule/:token',
      element: <SharedSchedule />
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    // { path: '*', element: <Navigate to="/404" replace /> }
    { path: '/', element: <Navigate to="/auth/login" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const AdminLogin = Loadable(lazy(() => import('../pages/authentication/AdminLogin')));
// const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
// Agency
const AgencyList = Loadable(lazy(() => import('../pages/dashboard/AgencyList')));
// Position
const PositionList = Loadable(lazy(() => import('../pages/dashboard/PositionList')));
// Service Status
const ServiceStatusList = Loadable(lazy(() => import('../pages/dashboard/ServiceStatusList')));
// Holiday
const HolidayList = Loadable(lazy(() => import('../pages/dashboard/HolidayList')));
// Billing
const BillingList = Loadable(lazy(() => import('../pages/dashboard/agency/BillingList')));
// Provider
const ProviderList = Loadable(lazy(() => import('../pages/dashboard/ProviderList')));
// Provider Rate
const ProviderRateList = Loadable(
  lazy(() => import('../pages/dashboard/provider/ProviderRateList'))
);
// News
const NewsForm = Loadable(lazy(() => import('../pages/dashboard/NewsForm')));
const NewsList = Loadable(lazy(() => import('../pages/dashboard/NewsList')));
// News
const NewsGallery = Loadable(lazy(() => import('../pages/provider-dashboard/NewsGallery')));
const NewsView = Loadable(lazy(() => import('../pages/provider-dashboard/NewsView')));
// Billing
const GuestBillingList = Loadable(
  lazy(() => import('../pages/provider-dashboard/billing/BillingList'))
);
// Bonus
const BonusForm = Loadable(lazy(() => import('../pages/dashboard/BonusForm')));
// Main
const FindProviderByCode = Loadable(
  lazy(() => import('../pages/provider-dashboard/FindProviderByCode'))
);
// Provider Home
const ProviderHome = Loadable(lazy(() => import('../pages/provider-dashboard/ProviderHome')));
const ClearanceList = Loadable(lazy(() => import('../pages/provider-dashboard/ClearanceList')));
const MySchedule = Loadable(lazy(() => import('../pages/provider-dashboard/MySchedule')));
const ZipCodeList = Loadable(lazy(() => import('../pages/provider-dashboard/ZipCodeList')));
const ProviderZipCodeList = Loadable(
  lazy(() => import('../pages/dashboard/provider/ProviderZipCodeList'))
);
const Email = Loadable(lazy(() => import('../pages/provider-dashboard/Email')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Guest
const SessionView = Loadable(lazy(() => import('../pages/guest/SessionView')));
const SharedSchedule = Loadable(lazy(() => import('../pages/shared-schedule/SharedSchedule')));
