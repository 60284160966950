import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import agencyReducer from './slices/agency';
import providerReducer from './slices/provider';
import clearanceReducer from './slices/clearance';
import personalCodeReducer from './slices/personal-code';
import newsReducer from './slices/news';
import serviceStatusReducer from './slices/service-status';
import sessionReducer from './slices/session';
import billingReducer from './slices/billing';
import holidayReducer from './slices/holiday';
import agencyBillingReducer from './slices/agency-billing';
import bonusReducer from './slices/bonus';
import positionReducer from './slices/position';
import positionRateReducer from './slices/provider-rate';
import scheduledSessionReducer from './slices/scheduled-session';
import providerZipcodePriorityReducer from './slices/provider-zipcode-priority';
import emailReducer from './slices/email';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  agency: agencyReducer,
  provider: providerReducer,
  clearance: clearanceReducer,
  personalCode: personalCodeReducer,
  news: newsReducer,
  serviceStatus: serviceStatusReducer,
  session: sessionReducer,
  billing: billingReducer,
  holiday: holidayReducer,
  agencyBilling: agencyBillingReducer,
  bonus: bonusReducer,
  position: positionReducer,
  providerRate: positionRateReducer,
  scheduledSession: scheduledSessionReducer,
  providerZipcodePriority: providerZipcodePriorityReducer,
  email: emailReducer
});

export { rootPersistConfig, rootReducer };
